<template>
  <div>
    <q-form ref="editForm">
      <c-card class="cardClassDetailForm" title="상세">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn 
              v-if="editable" 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="construction"
              :mappingType="mappingType"
              label="저장" 
              icon="save"
              @beforeAction="saveConstruction"
              @btnCallback="saveConstructionCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <c-text
              :disabled="true"
              :editable="editable"
              label="공사명"
              name="constructionName"
              v-model="construction.constructionName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-datepicker
              :disabled="true"
              :editable="editable"
              :range="true"
              label="공사기간"
              name="period"
              v-model="construction.period"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-vendor
              :disabled="true"
              :editable="editable"
              label="주 공사업체"
              name="vendorCd"
              v-model="construction.vendorCd">
            </c-vendor>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-checkbox
              :disabled="true"
              :editable="editable"
              :isFlag="true"
              label="공사종료여부"
              name="constructionFinishFlag"
              v-model="construction.constructionFinishFlag"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-text
              :disabled="true"
              :editable="editable"
              type="number"
              label="안전보건관리비"
              name="safetyHealthManageCost"
              v-model="construction.safetyHealthManageCost">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-text
              :disabled="true"
              :editable="editable"
              label="코스트센터"
              name="costCenter"
              v-model="construction.costCenter">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-text
              :disabled="true"
              :editable="editable"
              label="현장 총괄"
              name="onSiteManager"
              v-model="construction.onSiteManager">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-text
              :disabled="true"
              :editable="editable"
              label="안전책임자"
              name="safetyManager"
              v-model="construction.safetyManager">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-textarea
              :disabled="true"
              :editable="editable"
              :rows="2"
              label="공사내역"
              name="constructionDetails"
              v-model="construction.constructionDetails">
            </c-textarea>
          </div>
        </template>
      </c-card>
    </q-form>
    <c-table
      ref="vendortable"
      title="공사 참여업체 목록"
      :columns="gridColumns"
      :data="gridData"
      :gridHeight="gridVendor.height"
      :editable="editable"
      :hideBottom="true"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
    >
      <template slot="suffixTitle">
        <font class="text-negative" style="font-size:0.9em;font-weight:600;">
          ※ 로그인한 자의 업체 정보만 확인가능합니다.
        </font>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'constructionInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopConstructionId: '',
      }),
    },
    construction: {
      type: Object,
      default: () => ({
        sopConstructionId: '',  // 공사 일련번호
        constructionName: '',  // 공사명
        constructionDetails: '',  // 공사내역
        constructionStartDate: '',  // 공사 시작일
        constructionEndDate: '',  // 공사 종료일
        period: [],
        constructionFinishFlag: 'N',  // 공사 종료 여부
        safetyHealthManageCost: 0,  // 안전보건관리비(총 액)
        costCenter: '',  // 코스트센터
        onSiteManager: '',  // 현장 총괄
        safetyManager: '',  // 안전책임자
        vendorCd: '',  // 주 공사업체
        sopMocId: '',  // MOC 일련번호
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        vendors: [], // 참영업체
        deleteVendors: [], // 참영업체 [삭제]
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      gridVendor: {
        height: '500px',
      },
      editable: true,
      mappingType: 'PUT',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return String(this.contentHeight - 400) + 'px';
    },
    gridColumns() {
      return [
        {
          name: 'vendorName',
          field: 'vendorName',
          label: '참여업체',
          align: 'center',
          style: 'width:120px',
          sortable: false,
          fix: true,
        },
        {
          name: 'constructionDetails',
          field: 'constructionDetails',
          label: '공사내역',
          align: 'left',
          style: 'width:300px',
          sortable: false,
          type: 'textarea',
          disableTarget: 'vendorCd',
          disableCon: this.$store.getters.user.userId,
        },
        {
          name: 'period',
          field: 'period',
          label: '참여기간',
          align: 'center',
          style: 'width:200px',
          sortable: false,
          type: 'date',
          range: true,
          disableTarget: 'vendorCd',
          disableCon: this.$store.getters.user.userId,
        },
        {
          name: 'attach1',
          field: 'attach1',
          label: '안전관리계획서',
          align: 'center',
          style: 'width:150px',
          type: 'attach',
          taskClassCd: 'SCM_CONSTRUNCTION_SAFETY_MANAGE_PLAN',
          keyText: 'sopConstructionRelationVendorId',
          sortable: false,
          disableTarget: 'vendorCd',
          disableCon: this.$store.getters.user.userId,
        },
        {
          name: 'attach2',
          field: 'attach2',
          label: '공사(작업)위험성평가',
          align: 'center',
          style: 'width:150px',
          type: 'attach',
          taskClassCd: 'SCM_CONSTRUNCTION_RISK_ASSESS',
          keyText: 'sopConstructionRelationVendorId',
          sortable: false,
          disableTarget: 'vendorCd',
          disableCon: this.$store.getters.user.userId,
        },
        {
          name: 'attach3',
          field: 'attach3',
          label: '작업계획서',
          align: 'center',
          style: 'width:150px',
          type: 'attach',
          taskClassCd: 'SCM_CONSTRUNCTION_WORK_PLAN',
          keyText: 'sopConstructionRelationVendorId',
          sortable: false,
          disableTarget: 'vendorCd',
          disableCon: this.$store.getters.user.userId,
        },
        {
          name: 'attach4',
          field: 'attach4',
          label: '교육결과',
          align: 'center',
          style: 'width:150px',
          type: 'attach',
          taskClassCd: 'SCM_CONSTRUNCTION_EDU_CERTI',
          keyText: 'sopConstructionRelationVendorId',
          sortable: false,
          disableTarget: 'vendorCd',
          disableCon: this.$store.getters.user.userId,
        },
        {
          name: 'attach5',
          field: 'attach5',
          label: '산업안전보건관리비 계획',
          align: 'center',
          style: 'width:150px',
          type: 'attach',
          taskClassCd: 'SCM_CONSTRUNCTION_MANAGE_COST_PLAN',
          keyText: 'sopConstructionRelationVendorId',
          sortable: false,
          disableTarget: 'vendorCd',
          disableCon: this.$store.getters.user.userId,
        },
        {
          name: 'attach6',
          field: 'attach6',
          label: '보험 증명원',
          align: 'center',
          style: 'width:150px',
          type: 'attach',
          taskClassCd: 'SCM_CONSTRUNCTION_INSURANCE_CERTI',
          keyText: 'sopConstructionRelationVendorId',
          sortable: false,
          disableTarget: 'vendorCd',
          disableCon: this.$store.getters.user.userId,
        },
        {
          name: 'attach7',
          field: 'attach7',
          label: '검진결과',
          align: 'center',
          style: 'width:150px',
          type: 'attach',
          taskClassCd: 'SCM_CONSTRUNCTION_HEA_CHECK',
          keyText: 'sopConstructionRelationVendorId',
          sortable: false,
          disableTarget: 'vendorCd',
          disableCon: this.$store.getters.user.userId,
        },
        {
          name: 'attach8',
          field: 'attach8',
          label: '안전검사증',
          align: 'center',
          style: 'width:150px',
          type: 'attach',
          taskClassCd: 'SCM_CONSTRUNCTION_SAFETY_INSP_CERTI',
          keyText: 'sopConstructionRelationVendorId',
          sortable: false,
          disableTarget: 'vendorCd',
          disableCon: this.$store.getters.user.userId,
        },
        {
          name: 'attach9',
          field: 'attach9',
          label: '설비제원표',
          align: 'center',
          style: 'width:150px',
          type: 'attach',
          taskClassCd: 'SCM_CONSTRUNCTION_EQUIP_SPEC_TABLE',
          keyText: 'sopConstructionRelationVendorId',
          sortable: false,
          disableTarget: 'vendorCd',
          disableCon: this.$store.getters.user.userId,
        },
        {
          name: 'attach10',
          field: 'attach10',
          label: '자격증명서<br/>(지게차, 크레인 등)',
          align: 'center',
          style: 'width:150px',
          type: 'attach',
          taskClassCd: 'SCM_CONSTRUNCTION_CREDENTIALS',
          keyText: 'sopConstructionRelationVendorId',
          sortable: false,
          disableTarget: 'vendorCd',
          disableCon: this.$store.getters.user.userId,
        },
        {
          name: 'attach11',
          field: 'attach11',
          label: '기타',
          align: 'center',
          style: 'width:150px',
          type: 'attach',
          taskClassCd: 'SCM_CONSTRUNCTION_ETC',
          keyText: 'sopConstructionRelationVendorId',
          sortable: false,
          disableTarget: 'vendorCd',
          disableCon: this.$store.getters.user.userId,
        },
      ];
    },
    gridData() {
      return this.$_.filter(this.construction.vendors, { vendorCd: this.$store.getters.user.userId })
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.scm.construction.insert.url;
      this.deleteUrl = transactionConfig.sop.scm.construction.delete.url;
      // code setting
      // list setting
    },
    research() {
      this.$emit('getDetail')
    },
    saveConstruction() {
      if (this.isOld) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.sop.scm.construction.update.url;
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.sop.scm.construction.insert.url;
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.construction.regUserId = this.$store.getters.user.userId
              this.construction.chgUserId = this.$store.getters.user.userId
              
              this.construction.constructionStartDate = this.construction.period[0]
              this.construction.constructionEndDate = this.construction.period[1]
              
              if (this.construction.vendors && this.construction.vendors.length > 0) {
                this.$_.forEach(this.construction.vendors, vendor => {
                  if (vendor.period && vendor.period.length === 2 && vendor.period[0]) {
                    this.$set(vendor, 'participationStartDate', vendor.period[0])
                    this.$set(vendor, 'participationEndDate', vendor.period[1])
                  } else {
                    this.$set(vendor, 'participationStartDate', null)
                    this.$set(vendor, 'participationEndDate', null)
                  }
                })
              }

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveConstructionCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'sopConstructionId', _result.data)
      this.$emit('getDetail');
    },
  }
};
</script>